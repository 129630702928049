import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-4 d-flex" }
const _hoisted_2 = { class: "row no-gutters align-items-start w-100 m-auto" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "text-dark display-2 mb-3" }
const _hoisted_5 = { class: "mb-0 text-dark" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "badge badge-light ml-auto mt-1" }
const _hoisted_8 = { class: "badge badge-light ml-auto mt-1" }
const _hoisted_9 = { class: "badge badge-light ml-auto mt-1" }
const _hoisted_10 = { class: "col-9" }
const _hoisted_11 = { class: "col-12 mb-3" }
const _hoisted_12 = {
  key: 0,
  class: "col-12"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "ml-auto badge badge-primary" }
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "ml-auto badge badge-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_widget_statistics = _resolveComponent("widget-statistics")!
  const _component_widget_blog = _resolveComponent("widget-blog")!
  const _component_widget_articles = _resolveComponent("widget-articles")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_b_card, { class: "text-center h-100 mb-3 shadow-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_link, {
                  href: "#",
                  to: { name: 'Home.profile' }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'user-circle']
                      }, null, 8, ["icon"])
                    ]),
                    _createVNode(_component_b_form_group, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('greeting', { name: (_ctx.storedUser.firstname + ' ' + _ctx.storedUser.lastname) })), 1)
                      ], undefined, true),
                      _: 1
                    }),
                    (!_ctx.user.newsletter)
                      ? (_openBlock(), _createBlock(_component_b_button, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('newsletterSubscription')), 1)
                          ], undefined, true),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], undefined, true),
                  _: 1
                }, 8, ["to"]),
                (_ctx.user.newsletter)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('subscribed')), 1))
                  : _createCommentVNode("", true)
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }),
        (_ctx.user.admin)
          ? (_openBlock(), _createBlock(_component_b_card, {
              key: 0,
              title: _ctx.$t('card.admin.title'),
              class: "mb-3 shadow-sm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_link, {
                  to: { name: 'Admin.Users.New' },
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center w-100 text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'plus'],
                      class: "mr-2"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('card.admin.addUser')) + " ", 1),
                    _createElementVNode("div", _hoisted_7, "Total : " + _toDisplayString(_ctx.getCountUsers), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_b_link, {
                  to: { name: 'Admin.Users.New' },
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center w-100 text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'plus'],
                      class: "mr-2"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('card.admin.addCompany')) + " ", 1),
                    _createElementVNode("div", _hoisted_8, "Total : " + _toDisplayString(_ctx.getCountCompany), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_b_link, {
                  to: { name: 'Admin.Newsletter.New' },
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'plus'],
                      class: "mr-2"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" Newsletter "),
                    _createElementVNode("div", _hoisted_9, "Total : " + _toDisplayString(_ctx.getCountUsersNewsletter), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["to"])
              ], undefined, true),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.user.admin)
          ? (_openBlock(), _createBlock(_component_b_card, {
              key: 1,
              title: "Site AB+",
              class: "p-0 mb-3 shadow-sm"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_link, {
                  href: _ctx.siteBaseURl + '/wp-admin/post-new.php',
                  target: "_blank",
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center w-100 text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'plus'],
                      class: "mr-2"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('actualitesArticles')), 1)
                  ], undefined, true),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_b_link, {
                  href: _ctx.siteBaseURl + '/wp-admin/post-new.php?post_type=page',
                  target: "_blank",
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center w-100 text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'plus'],
                      class: "mr-2"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" Page ")
                  ], undefined, true),
                  _: 1
                }, 8, ["href"]),
                _createVNode(_component_b_link, {
                  href: _ctx.siteBaseURl + '/wp-admin/plugins.php',
                  target: "_blank",
                  variant: "primary",
                  class: "mb-1 d-flex align-items-center w-100 text-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: [_ctx.$store.family, 'puzzle-piece'],
                      class: "mr-1"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" Extensions ")
                  ], undefined, true),
                  _: 1
                }, 8, ["href"])
              ], undefined, true),
              _: 1
            }))
          : _createCommentVNode("", true),
        false
          ? (_openBlock(), _createBlock(_component_b_card, {
              key: 2,
              class: "shadow-sm",
              title: _ctx.$t('card.demonstration.title')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_link, {
                      class: "badge badge-primary mr-2",
                      to: {name: 'Home.mng'}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("# MNG Example")
                      ], undefined, true),
                      _: 1
                    }, 8, ["to"]),
                    _createVNode(_component_b_link, { class: "badge badge-primary mr-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode("# MNG 6.1 Example")
                      ], undefined, true),
                      _: 1
                    }),
                    _createVNode(_component_b_link, { class: "badge badge-primary mr-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode("# MNG 6.0 Example")
                      ], undefined, true),
                      _: 1
                    })
                  ], undefined, true),
                  _: 1
                })
              ], undefined, true),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_widget_statistics),
          _createVNode(_component_widget_blog),
          _createVNode(_component_widget_articles)
        ]),
        (_ctx.user.company.product == 'SOLU-QIQ')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_b_card, {
                    class: "shadow-sm mb-3",
                    title: _ctx.$t('card.lastPatch.title')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_card_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Patchnote['versions_SQBase'], (item, index) => {
                            return (_openBlock(), _createBlock(_component_b_list_group_item, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Home.Patchnote' }))),
                              key: index,
                              class: "d-flex align-items-center"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.version) + " ", 1),
                                _createElementVNode("span", _hoisted_15, _toDisplayString(item.date), 1)
                              ], undefined, true),
                              _: 2
                            }, 1024))
                          }), 128))
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }, 8, ["title"])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_b_card, {
                    class: "shadow-sm",
                    title: _ctx.$t('card.lastPatchWeb.title')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_card_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Patchnote['versions_SQWeb'], (item, index) => {
                            return (_openBlock(), _createBlock(_component_b_list_group_item, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('Patchenote'))),
                              key: index,
                              class: "d-flex align-items-center"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.version) + " ", 1),
                                _createElementVNode("span", _hoisted_17, _toDisplayString(item.date), 1)
                              ], undefined, true),
                              _: 2
                            }, 1024))
                          }), 128))
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  }, 8, ["title"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}