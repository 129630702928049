export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "actualities": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités Site AB+"])},
          "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])}
        }
      },
      "en": {
        "actualities": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles Website AB+"])},
          "editButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
        }
      }
    }
  })
}
