<template>
  <b-overlay :show="load" rounded="sm">
    <b-card class="shadow-sm" :title="$t('actualities.title')">
      <b-card-text style="min-height:140px">
        <div class="w-100" v-for="(post, index) in actualites" :key="index">
          <div class="d-flex align-items-center mb-2" >
            <b-link  target="_blanck" :href="post.link">
              <h6 class="">
                <div class="badge badge-info mr-2">#</div>
                {{ post.title }}
              </h6>
            </b-link>
            <b-link class="ml-auto" v-if="user.admin" target="_blank" :href="siteBaseURl + '/wp-admin/post.php?post=' + getPostID(post.guid) + '&action=edit'">
              <span class="badge bg-warning text-dark">{{ $t('actualities.editButton') }}</span>
            </b-link>
            <b-badge variant="primary" :class="user.admin ? '' : 'ml-auto'" class="ml-2">{{ $filters.calendar(post.pubDate) }}  </b-badge>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script  lang="ts">
import { mapState } from 'vuex'
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      load: false,
      actualites: [],
      siteBaseURl: ''
    }
  },
  created() {
    this.load = true
    api.dashboard.getArticleSite()
      .then(response => response.data)
        .then(data => {
          if (data.status) {
            this.actualites = data.results.channel.item
            this.siteBaseURl = data.results.baseURL
            this.load = false
          }
        })
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods:{
    getPostID(guid: string): string{
      return guid[0].split('/?p=')[1]
    }
  }
})
</script>

<i18n>
  {
    "fr": {
      "actualities": {
        "title": "Actualités Site AB+",
        "editButton": "Modifier"
      }
    },
    "en": {
      "actualities": {
        "title": "Articles Website AB+",
        "editButton": "Edit"
      }
    }
  }
</i18n>
