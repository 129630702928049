<template>
  <footer class="bg-dark border-top text-light shadow py-2">
    <b-container>
      <div class="row">
        <div class="col-auto">
          <h6 class="">{{ $t('list.usefull.title') }}</h6>
          <ul class="pl-2 list-unstyled">
            <li>
              <a class="text-500" href="http://abplussoftware.fr">AB+ SOFTWARE</a>
            </li>
            <li>
              <a class="text-500" href="/">{{ $t('list.usefull.home') }}</a>
            </li>
          </ul>
        </div>
        <div class="col-auto">
          <h6 class="">{{ $t('list.socialNetworks.title') }}</h6>
          <ul class="pl-2 list-unstyled">
            <li>
              <a class="text-500" href="">Facebook</a>
            </li>
          </ul>
        </div>
        <div class="ml-auto col-5 d-flex text-left">
          <font-awesome-icon class="mt-1 mr-3" :icon="[$store.state.family, 'info-circle']" />
          <p class="text-500 mb-0">
            {{ $t('cookieUsage')}}
          </p>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script  lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>

<i18n>
{
  "fr": {
    "list": {
      "usefull": {
        "title": "Liens utiles :",
        "home": "Accueil"
      },
      "socialNetworks": {
        "title": "Réseaux sociaux"
      }
    },
    "cookieUsage": "En utilisant ce site web, vous consentez à l'utilisation des cookies obligatoires au bon fonctionnement du site."
  },
  "en": {
    "list": {
      "usefull": {
        "title": "Usefull links:",
        "home": "Home"
      },
      "socialNetworks": {
        "title": "Social networks"
      }
    },
    "cookieUsage": "By using this site, you agree the use of required cookies."
  }
}
</i18n>
